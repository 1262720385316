'use strict';


angular.module('kljDigitalLibraryApp')

.controller('SubCtrl', ["$scope", "$rootScope", "$state", "$stateParams",
    "commonVariables", "SubscriptionService", "OrderService", "$uibModal",
    function($scope, $rootScope, $state, $stateParams,
        commonVariables, SubscriptionService, OrderService, $uibModal) {

        $rootScope.$broadcast('isHome');

        var constants = {
            HEADER_HEIGHT_1024: 46,
            BUY: 'buy',
            RENEW: 'renew'
        }
        $scope.user = {};
        var init = function() {
            var mapping = {
                'current-plan': 'current',
                'upcoming-plan': "upcoming",
                'trial-plan': 'trial',
                'other-plan': 'other',
                'accessocde-subscription':'accesscode'
            }
            $rootScope.title = mapping[$state.params.plan];
            if ($state.params) {
                $scope.plan = $state.params.plan;
            }
            if ($stateParams.subscriptionId) {
                subscriptionDetails();
            } else if ($state.current.name === 'index.subscriptionHistory') {
                getSubscriptionHistory();
            } else if ($stateParams.subId) {
                subscriptionOrderDetails();
            } else {
                getMySubscriptions();
            }
            $scope.showTrial = true;
            updatePageTitle($state.params.plan);
        }

        function updatePageTitle(key) {
            switch (key) {
                case 'upcoming':
                    $rootScope.title = 'Upcoming Plans | Subscriptions';
                    break;

                case 'current':
                    $rootScope.title = 'Current Plans | Subscriptions';
                    break;

                case 'expired':
                    $rootScope.title = 'Expired Plans | Subscriptions';
                    break;

                case 'trial':
                    $rootScope.title = 'Trail Plans | Subscriptions';
                    break;

                case 'other':
                    $rootScope.title = 'Premium Plans | Subscriptions';
                    break;

                case 'history':
                    $rootScope.title = 'Subscription history';
                    break;
            }

            if ($state.current.name == 'index.subscriptionHistory') {
                $rootScope.title = 'Subscription history';
            }
        }

        commonVariables.isHome = false;

        $rootScope.title = 'Subscription';
        if ($state.params) {
            $scope.operation = $state.params.operation;
        }

        $scope.getNumber = function(num) {
            return new Array(num);
        }

        angular.element(document).ready(function() {

            $('.wrapper-inner').css('height', $(window).height() - constants.HEADER_HEIGHT_1024 + "px");
        });


        // check if user is already registered to suggested plan

        var checkPlanType = function(arr1, arr2) {
            for (var i = 0; i < arr1.length; i++) {
                for (var j = 0; j < arr2.length; j++) {
                    if (arr1[i].subscription_id == arr2[j]._id) {
                        arr2[j].plan_type = 'Renew';
                    } else {
                        arr2[j].plan_type = 'Buy';
                    }
                }
            }
            return arr2;
        }

        var dividePlans = function(list) {
            var result = {
                    premiumPlans: []
                },
                count = 0;
            for (var i = 0; i < list.length; i++) {
                if (list[i].subscription.plan_type === 'Regular') {
                    result.premiumPlans.push(list[i]);
                }
                // else {
                //   if(list[i].subscribed === true) {
                //     count++;
                //   }
                //   result.trialPlans.push(list[i]);
                // }
            }
            // if(count === 2) {
            //   $scope.showTrial = false;
            // }
            return result.premiumPlans;
        }
       var FilterCurrentPlan = function(currentPLan, type){
           let new_current_plan = [];
           let expired_plan = [];
            var date = new Date();
               for(let i=0; i<currentPLan.length; i++){
                   let start_date = new Date(currentPLan[i].start_date);
                   let expiry_date = new Date(currentPLan[i].expiry_date)
                  if(start_date <= date && expiry_date >= date){
                      new_current_plan.push(currentPLan[i]);
                  }else if(start_date <=date && expiry_date <= date){
                      expired_plan.push(currentPLan[i]);
                  }

            }
            if(type === 'current'){
                return new_current_plan;
            } else if(type === 'expired'){
                return expired_plan;
            }


       }
        //Get user current, upcoming, suggestions and history of subscriptions

        var getMySubscriptions = function() {

            return SubscriptionService.personalList()
                .then(function(data) {
                    // $scope.subscriptionPlans = dividePlans(data.all_subscriptions);
                    console.log("data subscription-->", data);
                    $scope.myPlans = {
                        current: FilterCurrentPlan(data.current_subscriptions, 'current'),
                        upComing: data.upcoming_subscriptions,
                        expired: FilterCurrentPlan(data.current_subscriptions, 'expired'),
                        history: data.history,
                        premiumPlans: dividePlans(data.all_subscriptions),
                        trialPlans: data.trial_subscriptions,
                    };
                    console.log("myPLans=====>>>>",  $scope.myPlans);
                })
                .catch(function(error) {

                });

        }

        //Get user subscription history

        var getSubscriptionHistory = function() {

            return SubscriptionService.getHistory()
                .then(function(data) {
                    $scope.history = data;
                })
                .catch(function(error) {

                });

        }

        // Get subscription details
        var subscriptionDetails = function() {
            return SubscriptionService.getSubscriptionDetails($stateParams.subscriptionId)
                .then(function(response) {
                    $scope.planDetails = response;
                    //Check whether user has paticular subscription
                    $rootScope.title = response.title + " | " + response.plan_type + " | " + response.category + " | " + response.mode;
                    SubscriptionService.checkStatus({
                            id: $stateParams.subscriptionId
                        })
                        .then(function(response) {
                            $scope.planDetails.subId = $stateParams.subscriptionId;
                            $scope.planDetails.type = (response === true ? 'Renew' : 'Buy');

                        })

                    .catch(function(error) {

                    });
                })

            .catch(function(error) {

            });

        }


        // Get subscription details
        var mySubscriptionDetails = function(id) {
            return SubscriptionService.getMySubscription(id)
                .then(function(data) {
                    $scope.planDetails = data;

                })

            .catch(function(error) {

            });
        }

        //Subscrition Order details
        var subscriptionOrderDetails = function() {
            return OrderService.subcriptionOrderDetails($stateParams.subId)
                .then(function(data) {
                    $scope.plan = {
                        details: data.subscription,
                        order: data.order
                    };
                    if (data.order.shipment) {
                        var len = data.order.shipment.length;
                        if (len > 0) {
                            if (data.order.shipment[len - 1].tracking_number) {
                                $scope.shipment = data.order.shipment[len - 1];
                            }
                        }
                    }

                    // $scope.plan.details.type = "Renew";
                    //Check whether user has paticular subscription
                    SubscriptionService.checkStatus({
                            id: $stateParams.subId
                        })
                        .then(function(response) {
                            $scope.plan.details.type = (response === true ? 'Renew' : 'Buy');
                        })

                    .catch(function(error) {

                    });
                })
                .catch(function(error) {});
        }

        //Track Order
        $scope.getTrackingInfo = function(data) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'trackingInfoBox.html',
                controller: 'OrderTrackCtrl',
                size: 100,
                resolve: {
                    shipment: function() {
                        return data;
                    }
                }
            });
        }

        // Checkout
        $scope.checkOut = function(plan) {
            plan.buy = true;
            $scope.buySubscription(plan);

        }

        // Make payment
        $scope.makePayment = function(plan) {
            plan.buy = false;
        }

        //Apply coupon code
        $scope.applyCode = function(plan, couponCode) {
            plan_type.couponCode = couponCode;
            if (plan.couponCode) {
                return SubscriptionService.promoCodeVerification(plan)
                    .then(function(response) {
                        $scope.codeDetails = response;
                    })

                .catch(function(error) {

                });
            } else {
                $scope.codeDetails = {
                    code: 404,
                    message: "Please enter coupon code"
                }
            }

        }

        /**
        Redirect to order page
        */

        $scope.buySubscription = function(planDetails) {

            if (planDetails.buy) {
                $state.go('index.order', {
                    subscription: planDetails.subscription._id
                });
            }

        }

        //Event Emiters: communication between controllers
        $scope.$emit('showSearchBox', true);
        if(!$rootScope.offlineApp){
            init();
        }
        else{
            SubscriptionService
                .getExpiryDate()
                .then(function(result){
                    $scope.offlineApp=$rootScope.offlineApp;
                    $scope.expiry_date=result.expiry_date;
                })
                .catch(function(err){
                    console.log(err,"=======")
                });
        }


        var get_accesscode_order_intent_details = function(body) {


            return SubscriptionService.get_order_intent(body);
        }

        function is_valid_access_code(acode){
            if(!acode){
                return {status: false, message:"Please Enter access code"};

            }
            if(acode.length!=16){
                return {status: false, message:"Accesscode should be of 16 letters"};
            }
            return {status:true, message:''}
        }


        $scope.showAccesscodeInput=true;
        $scope.generate_accesscode_order_intent = function() {
           event.preventDefault();
           $scope.accesscodePlan = {};
           $scope.confirmationBtn = false;
           $scope.user.accesscode = $scope.user.accesscode1 + $scope.user.accesscode2 + $scope.user.accesscode3 + $scope.user.accesscode4;
           var validity_check = is_valid_access_code($scope.user.accesscode);
           if(validity_check.status){
                get_accesscode_order_intent_details({"access_code":$scope.user.accesscode})
                .then(function(data){

                console.log("data>>>>>>>>>>>>>>>>>>>>> ",data);
                if(!data.status){
                    $scope.wrongAccesscode=true;
                    $scope.errorMessage=data.message;
                    return;
                }
                $scope.accesscodePlan = data.subscription;
                $scope.txnid = data.txnid;
                console.log("data subscription >>>>>>>>>> ", $scope.accesscodePlan);
                var obj = {id:data.subscriptionId, accesscode:$scope.user.accesscode, txnid:data.txnid}
                $scope.showAccesscodeInput=false;
                $scope.confirmationBtn = true;
                // $state.go('main.accesscode-plan-details', obj )

                // $scope.planDetails=data.data;
                // $scope.showPlandetails=true;

            })
            .catch(function(err){

                $scope.wrongAccesscode=true;
                $scope.errorMessage = err.data.message;
                console.log("err>>>>>>>>>>>>>>> ",err);
            })
           }
            else{
                $scope.wrongAccesscode=true;
                $scope.errorMessage = validity_check.message;
            }

        }

        //Make Confirmation
        $scope.confirm_accesscode_subscription = function(plan) {
            if($scope.txnid ){
                SubscriptionService.confirmOrder(null, $scope.txnid, null)

                .then(function(data) {
                        console.log("data----", data);
                        if(data.status){
                            $scope.confirmSuccessMessage = true;
                            $scope.confirmationBtn = false;
                            return;
                        }
                        $scope.confirmErrorMessage = true;
                        $scope.errorMessage = data.message;
                        $scope.confirmationBtn = false;
                        return;

                    })
                    .catch(function(error) {
                        console.log("error>>>>>>>>>> ",error);

                        // $scope.confirmErrorMessage = true;
                        $scope.confirmErrorMessage = true;
                        $scope.errorMessage = error.data.message;
                        $scope.confirmationBtn = false;
                    })
                }

            else{

                 $scope.confirmErrorMessage = true;
                 $scope.errorMessage = "Invalid Transaction"
                 $scope.confirmationBtn = false;
            }
        }


        $scope.cancel_subscription = function(){
            // $state.go('index');
        }




    }
]);
